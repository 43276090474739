import React from 'react';
import I18n from '@utils/i18n';
import helper from '@utils/helper';
import { getRemoteConfigValue } from '@utils/firebase'
import { previewVideo } from '@element/Video/preview'
import cn from 'classnames';
import styles from './index.module.css';

let video = 'https://upload.dipbit.xyz/2021111610033135487500/longtext/e44b2b775f4648abb1f9bdce18e29a00.mp4'
export const MovieBtn = ({ className }) => {
  const onClick = async () => {
    try {
      const res = await getRemoteConfigValue(`community_video_web_${helper.getLocaleKey()}`)
      video = JSON.parse(res.asString()).video
    } catch (error) {
    }
    previewVideo({
      videoSource: video,
      poster: `${video}?x-oss-process=video/snapshot,t_0,w_1920,h_1080,m_fast`
    })
  }

  return (
    <div className={cn(styles.movieBtn, className)} onClick={onClick} data-buried="vcCa3ufE">
      <span className={styles.text}>{I18n.t('vcCa3ufE')}</span>
      <span className={cn('icon', ' icon-play', styles.icon)} />
    </div>
  );
}
