import React from 'react';
import cn from 'classnames';
import styles from './index.module.css';

export const ModuleTitle = (props) => {
  const { className, children } = props;
  return (
    <div className={cn(styles.h2, className)}>{children}</div>
  )
}
