import React from 'react';

import cn from 'classnames'
import Image from 'next/legacy/image'
import Link from 'next/link'

import Icon from '@components/Icon'
import en from '@images/logo_en.webp'
import hk from '@images/logo_hk.webp'
import zh from '@images/logo_zh.webp'
import { useAuthSession, useClient } from '@lib/hooks'
import helper from '@utils/helper'
import I18n from '@utils/i18n';

import styles from './index.module.css'

const ImgMap = {
  zh,
  hk,
  en
}

const registerButtom = (isLogin) => {
  if (!isLogin) {
    return <Link legacyBehavior href={'/login?loginType=wallet'}>
            <div className={styles.quick} data-buried="TRkOl8IA">
              <span>{I18n.t('TRkOl8IA')}</span>
              <Icon name='arrowRight'/>
          </div>
         </Link>
  }
  return ''
}

const QuickStart = () => {
  const isPc = useClient()

  const isLogin = useAuthSession({ redirectTo: null });
  if (isPc === '') {
    return <div className={styles.emptyStyle}></div>
  }
  const titleDom = <h1 className={cn(styles.line1, 'Medium')}>{I18n.t('uuNCpwPP')}</h1>
  return (
    <div className={styles.pageTop} key='web'>
      <div className={styles.contain}>
          <div className={styles.left}>
            {registerButtom(isLogin)}
          {titleDom}
          <p className={styles.line2}>{I18n.t('vqu8j53i')}</p>

          </div>
          <div className={styles.right}>
                    <Image
                      src={ImgMap[helper.getLocaleKey()]}
                      layout="fixed"
                      width={588}
                      height={498}
                      alt=''
                  />
          </div>
        </div>
    </div>
  );
}

export default QuickStart
