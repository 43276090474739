import React, { useState } from 'react';

import cn from 'classnames';

import { Swiper } from '@element/index';
import { useIsomorphicLayoutEffect } from '@lib/hooks'
import { getRemoteConfigValue } from '@utils/firebase'
import helper from '@utils/helper';

// import I18n from '@utils/i18n';
import { Announcement } from '../Announcement';
import MemoizedEventItem from '../MemoizedEventItem'

// import { ModuleTitle } from '../ModuleTitle';
import styles from './index.module.css'

const renderItem = (item, index) => {
  return (
    <MemoizedEventItem
      item={item}
      key={'event' + index}
      navigation={{
        navigate: ({ url }) => {
          url && window.open(url)
        }
      }}
    />
  )
}

export const Activity = () => {
  // const isLogin = useAuthSession({ redirectTo: null });
  const [eventCenterConfig, setEventCenterConfig] = useState([])

  // const {sizeMode} = useClientResize();
  useIsomorphicLayoutEffect(() => {
    getRemoteConfigValue(`event_center_web_${helper.getLocaleKey()}`).then((res) => {
      console.log(res, 'resres')
      setEventCenterConfig(JSON.parse(res.asString()))
    })
  }, [])
  return <>
    {/* <ModuleTitle className={cn('Medium', { [styles.mt40]: isLogin })}>
      <h2 className='Medium'>{I18n.t('WaEmnBbe')}</h2>
    </ModuleTitle> */}
    <div className={styles.mt56}>
      <Announcement />
    </div>
    <Swiper
      dataSource={eventCenterConfig}
      pageSize={4}
      renderItem={renderItem}
      data-buried="WaEmnBbe"
      listClass={cn(styles.list, { [styles.listLeft]: eventCenterConfig?.length < 4 })}
      itemClass={cn(styles.item, { [styles.listMargin]: eventCenterConfig?.length < 4 })}
    />
  </>;
}
