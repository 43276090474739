import React, { useState } from 'react';
import { inject, observer } from 'mobx-react'
import cn from 'classnames';
import styles from './index.module.css';
import I18n from '@utils/i18n';
import { Tabs, TabPane, Button } from '@element/index';
import useAuthSession from '@lib/hooks/useAuthSession'
import SocialFeed from '@widget/post/SocialFeed';
import { useRouter } from 'next/router';
import { COMMUNITY_PATHS } from '@constants/index';
import { ModuleTitle } from '@widget/home/ModuleTitle';
import { MovieBtn } from '@widget/home/MovieBtn'
import HotPerson from '@widget/community/hotPerson'
const newLimit = 11
const isLimit = true

const T = (props) => {
  const router = useRouter()
  const { userStore } = props;
  const [activeKey, setActiveKey] = useState('1');
  const isLogin = useAuthSession({ redirectTo: null });

  const clickMore = () => {
    router.push({
      pathname: COMMUNITY_PATHS.COMMUNITY,
      query: `activeKey=${activeKey}`
    })
  }

  return (
      <div className={styles.container}>
        <ModuleTitle className={cn(styles.title, { [styles.mt40]: !isLogin })}>
          <h2 className='DINPro-Medium'>{I18n.t('community')}</h2>
          <span className={styles.movieBtnContainer} ><MovieBtn className={styles.movieBtn}/></span>
        </ModuleTitle>
        <div className={styles.content}>
          <div className={styles.leftPart}>
            <Tabs
              type='marketTabCoin'
              activeKey={activeKey}
              onChange={(activeKey) => {
                setActiveKey(activeKey)
              }}
            >
            <TabPane tab={<h3 data-buried="community_recommend">{I18n.t('community_recommend')}</h3>} key='1' type='marketTab'>
                <div>
                  <SocialFeed
                    feedParamUser={'post'}
                    feedParamType={'recommend'}
                    limit={3}
                    isLoadMore={false}
                  />
              </div>
              </TabPane>
            <TabPane tab={<h3 data-buried="recent">{I18n.t('recent')}</h3>} key='2' type='marketTab'>
                <div>
                  <SocialFeed
                    feedParamUser={'post'}
                    feedParamType={'square'}
                    limit={3}
                    tab="recent"
                    isLoadMore={false}
                  />
                </div>
              </TabPane>
              {isLogin && (<TabPane tab={<h3 data-buried="profile_follow_action">{I18n.t('profile_follow_action')}</h3>} key='3' type='marketTab'>
                <div>
                  <SocialFeed
                    feedParamUser={'post'}
                    feedParamType={userStore?.userInfo?.id}
                    limit={3}
                    isLoadMore={false}
                  />
                </div>
              </TabPane>)}
            </Tabs>
            <div className={styles.moreBtn}>
              <div className={styles.moreBtnContainer}>
                <Button
                  content={<span className={cn(styles.btnText, 'Medium')}>{I18n.t('K9GJbNWK')}</span>}
                  onClick={clickMore}
                  data-buried="K9GJbNWK"
                />
              </div>
            </div>
          </div>
          <div className={styles.kikicommunityRight}>
            <HotPerson
              btnClassName={cn(styles.btnClassName)}
              className={cn(styles.kikicommunityHotPerson)}
              titleClass={cn(styles.kikiHotTitle)}
              newLimit={newLimit}
              isLimit={isLimit}
              followBtnClass={styles.ghost}
            />
          </div>
        </div>

      </div>
  )
}

export default inject(rootStore => {
  return {
    userStore: rootStore.store.userStore
  }
})(observer(T))
