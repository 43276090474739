import React, { useState } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import Link from 'next/link';

import { Scroll } from '@components/Element'
import { useIsomorphicLayoutEffect } from '@lib/hooks'
import { getRemoteConfigValue } from '@utils/firebase'
import helper from '@utils/helper';
import I18n from '@utils/i18n'

import styles from './index.module.css';

const moreLinkMap = {
  en: 'https://kikitrade.zendesk.com/hc/en-hk/categories/360000135095-KIKI-Announcement',
  'zh-CN': 'https://kikitrade.zendesk.com/hc/zh-cn/categories/360000135095-KIKI-%E5%85%AC%E5%91%8A',
  'zh-TC': 'https://kikitrade.zendesk.com/hc/zh-tw/categories/360000135095-KIKI-%E5%85%AC%E5%91%8A'
}

export const Announcement = () => {
  const [dataSource, setDataSource] = useState([])
  useIsomorphicLayoutEffect(() => {
    getRemoteConfigValue(`announcement_web_${helper.getLocaleKey()}`).then((res) => {
      let data = JSON.parse(res.asString())
      data = data?.sort((a, b) => {
        if (dayjs(a.publicDate).isAfter(b.publicDate)) {
          return -1
        }
        return 1
      })
      data = data.slice(0, 3).map(item => ({ title: `${item.title} ${dayjs(item.publicDate).format('MM-DD')}`, url: item.url }))
      setDataSource(data)
    })
  }, [])
  if (!dataSource?.length) {
    return null
  }
  return (
    <div className={styles.container}>
      <span className={cn('icon', 'icon-trumpet', styles.icon)} />
      <Scroll dataSource={dataSource} />
      <p className={styles.more}>
        <Link legacyBehavior href={moreLinkMap[I18n.locale] || moreLinkMap.en}>
          <a target="_blank" className={styles.a} data-buried="market_more"><span>{I18n.t('market_more')}</span></a>
        </Link>
      </p>
    </div>
  );
}
