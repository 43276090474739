import React, { useState } from 'react';

import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import { useRouter } from 'next/router';

import { MARKET_PATHS } from '@constants/index';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect';
import I18n from '@utils/i18n';
import { ModuleTitle } from '@widget/home/ModuleTitle';
import List from '@widget/market/TradeList/List';
import { getListData } from '@widget/market/TradeList/utils';

import styles from './index.module.css';

const pageSize = 5;

const T = (props) => {
  const router = useRouter();
  const {
    marketStore,
    marketStore: { fetchMarketCoin, fullMarketSymbols }
  } = props;
  const [loading, setLoading] = useState(false);

  const getHotCoinsData = () => {
    const dataSource = getListData('USDT', marketStore);
    return dataSource.slice(0, pageSize);
  };

  const clickMore = () => {
    router.push({
      pathname: MARKET_PATHS.MARKET
    });
  };

  useIsomorphicLayoutEffect(() => {
    async function fetchData () {
      setLoading(true);
      await Promise.all([
        fetchMarketCoin(),
        fullMarketSymbols()
      ]);
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <ModuleTitle>
          <h2 className={cn('Medium')}>{I18n.t('home_market')}</h2>
        </ModuleTitle>
        <span
          data-buried="more"
          className={styles.moreBtn}
          onClick={clickMore}
        >
          {I18n.t('more')}
        </span>
      </div>
      <List
        dataSource={getHotCoinsData()}
        loading={loading}
        page="HOME_HOT"
      />
    </div>
  );
};

export default inject((rootStore) => {
  return {
    marketStore: rootStore.store.marketStore,
    tradeStore: rootStore.store.tradeStore
  };
})(observer(T));
