import React, { useState } from 'react';

import cn from 'classnames';
import { useRouter } from 'next/router';

import * as API from '@api/index';
import { PROJECTS_PATHS } from '@constants/index';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect';
import helper from '@utils/helper'
import I18n from '@utils/i18n';
import { projectLanguage } from '@utils/projects/bannerLanguage'
import { ModuleTitle } from '@widget/home/ModuleTitle';

import ProjectsItem from './ProjectsItem';

import styles from './index.module.css';
const getLocaleKey = helper.getLocaleKey

const T = (props) => {
  const router = useRouter();
  const localkey = getLocaleKey();
  const [recommendAllData, setRecommendAllData] = useState([]);

  const clickMore = () => {
    router.push({
      pathname: PROJECTS_PATHS.PROJECTS
    });
  };

  useIsomorphicLayoutEffect(() => {
    fetchRecommendData();
  }, []);

  const fetchRecommendData = () => {
    const zone = projectLanguage(localkey);
    API.recommendProject({ zone }).then((response) => {
      if (response && response.result?.res) {
        const list = response.result?.res.rows || [];
        const recommendList = list.slice(0, 8);
        setRecommendAllData(recommendList);

        console.log(recommendList, 'recommendAllData')
      }
    }).catch((error) => {
      console.log(error, 'error')
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <ModuleTitle>
          <h2 className={cn('DINPro-Medium')}>{I18n.t('R7vCZFRX')}</h2>
        </ModuleTitle>
        <span
          data-buried="more"
          className={styles.moreBtn}
          onClick={clickMore}
        >
          {I18n.t('more')}
        </span>
      </div>
      <div className={styles.itemContainer}>
      {
        (recommendAllData || []).map(recommendItem => {
          return <ProjectsItem recommendItem={recommendItem} key={recommendItem.customerId} />
        })
      }
      </div>
    </div>
  );
};

export default T;
